var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-5 pr-5"},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"mt-4 mb-4"},[_vm._v("Version history")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.versions},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"select-book abc"},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[_c('v-autocomplete',{staticClass:"ma-3 my-1 select-item",attrs:{"dense":"","items":_vm.groupColumnValueList(header.value),"multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.toggle(header.value)}}},[_c('v-list-item-action',[(
                        _vm.filters[header.value].length ==
                        _vm.versions
                          .map(function (d) { return d[header.value]; })
                          .reduce(function (accumulator, element) {
                            if (accumulator.indexOf(element) === -1 && element === 'D1EX1') {
                              accumulator.push(element)
                            }
                            return accumulator
                          }, []).length
                      )?_c('v-icon',[_vm._v(" mdi-close-box ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
                      var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(" "+_vm._s(_vm.filters[header.value].length)+" selected ")]):_vm._e()]}}],null,true),model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}})],1):_vm._e()])}),0)]}},{key:"body",fn:function(ref){
                      var items = ref.items;
return _vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{style:({ width: '150px', textAlign: 'center' })},[_c('p',[_vm._v(_vm._s(item.version))])]),_c('td',{style:({ width: '150px', textAlign: 'center' })},[_c('p',[_vm._v(_vm._s(item.dayUpdate))])]),_c('td',[_c('p',{style:({ padding: '5px 10px' }),domProps:{"innerHTML":_vm._s(item.note)}})])])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }